export default class Error {
  constructor(fields) {
    this.errors = this.setErrors(fields);
  }

  setErrors(fields) {
    let errors = {};
    for (let field in fields) {
      errors[field] = '';
    }
    return errors;
  }

  any() {
    for (let field in this.errors) {
      if (this.errors[field]) {
        return true;
      }
    }
  }

  has(field) {
    if (this.errors[field]) {
      return true;
    }
    return false;
  }

  get(field) {
    return this.has(field) ? this.errors[field] : "";
  }

  clear(field) {
    if (field) {
      this.errors[field] = '';
    }
    else {
      this.errors = {};
    }
  }

  record(errors) {
    errors = errors.errors;

    for (let error in errors) {
      this.errors[error] = errors[error][0];
    }
  }
}
