import axios from 'axios'
import Error from '@/libs/core/Error'
import Vault from '@/libs/core/Vault'

export default class Form {
  constructor (fields) {
    this.fields = fields
    this.errors = new Error(fields)
  }

  submit (method, url, data = {}) {
    return new Promise((resolve, reject) => {
      axios({
        baseURL: process.env.VUE_APP_ADMIN_BFF,
        method, 
        url, 
        data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Vault.take('token')}`,
        }
      })
        .then(({ data }) => {
          resolve(data)
        }).catch(({ response }) => {
          this.errors.record(response.data)
          reject(response.data)
        })
    })
  }
}